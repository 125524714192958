<template lang="pug">
  .setting-row
    .column.dropdown-column
      .immutable(
        v-if="isCarClassImmutable"
        :title="selectedCarClassName"
      )
        span {{ selectedCarClassName }}
      AppDropdown.dropdown.car-class-name(
        v-else
        allow-empty
        close-on-select
        :is-item-disabled="isCarClassDisabled"
        :items="carClasses"
        :value="selectedCarClass"
        :class="{ invalid: isCarClassInvalid }"
        @select="handleSelectCarClass($event)"
        @remove="handleSelectCarClass({})"
      )
    .target-period-rows
      TargetPeriodRow(
        v-for="(period, index) in row.target_periods.$each.$iter"
        ref="targetPeriods"
        :key="extractTargetPeriodKey(period)"
        :index="parseInt(index)"
        :target-period="period"
        :jalan-points="jalanPoints"
        :show-add="showAdd(index)"
        :show-remove="showRemoveTargetPeriod"
        :class="{ 'm-b-2': !showAdd(index) }"
        :selected-car-class="selectedCarClass"
        :jalan-plan-id="jalanPlanId"
        :rakuten-plan-id="rakutenPlanId"
        @add-period="handleAddPeriod"
        @update-period="updateRow"
        @remove-period="handleRemovePeriod(parseInt(index))"
      )
      .add-period
        AppIconButton.add-period-button(
          v-if="noTargetPeriods"
          icon="plus-circle"
          @click="handleAddPeriod"
        )
    .column.actions
      AppIconButton.delete(
        v-if="showRemoveByPeriod"
        useAppIcon
        icon="trash"
        @click="handleDelete"
      )
</template>

<script>
  import { find, forEach } from "lodash-es"

  import { EMPTY_TARGET_PERIOD_OBJECT } from "@/config/constants"

  export default {
    components: {
      AppDropdown: () => import("@/components/elements/AppDropdown"),
      AppIconButton: () => import("@/components/elements/AppButton/WithIcon/Other"),
      AppInput: () => import("@/components/elements/AppInput"),
      TargetPeriodRow: () => import("./TargetPeriodRow")
    },

    props: {
      index: {
        type: Number,
        required: true
      },
      changeRow: {
        type: Function,
        required: true
      },
      deleteRow: {
        type: Function,
        required: true
      },
      row: {
        type: Object,
        required: true
      },
      carClasses: {
        type: Array,
        required: true
      },
      jalanPoints: {
        type: Array,
        required: true
      },
      isCarClassDisabled: {
        type: Function,
        required: true
      }
    },

    computed: {
      isCarClassImmutable() {
        return !!this.row.id.$model
      },

      selectedCarClassName() {
        return this.selectedCarClass?.name
      },

      jalanPlanId() {
        return this.row.jalan_plan_id.$model
      },

      rakutenPlanId() {
        return this.row.rakuten_plan_id.$model
      },

      selectedCarClass() {
        return find(this.carClasses, ({ jalan_plan_id, rakuten_plan_id }) => {
          return (
            (jalan_plan_id !== null && jalan_plan_id === this.jalanPlanId) ||
            (rakuten_plan_id !== null && rakuten_plan_id === this.rakutenPlanId)
          )
        })
      },

      isCarClassInvalid({ row: { jalan_plan_id, rakuten_plan_id } }) {
        return jalan_plan_id.$error || rakuten_plan_id.$error
      },

      isNewRow() {
        return this.row.id.$model === undefined
      },

      showRemoveTargetPeriod() {
        return this.row.target_periods.$model.length > 1 || !this.isNewRow
      },

      showRemoveByPeriod() {
        return this.noSavedTargetPeriods || this.isNewRow
      },

      noTargetPeriods() {
        return this.row.target_periods.$model.length === 0
      },

      noSavedTargetPeriods() {
        return this.row.$model.no_saved_target_periods
      }
    },

    methods: {
      extractTargetPeriodKey(period) {
        return `${this.row.id.$model || this.row._addedAt.$model}-${period.id.$model || period._addedAt.$model}`
      },

      handleDelete() {
        this.deleteRow(this.index)
      },

      updateRow() {
        this.changeRow({ item: this.row.$model, index: this.index })
      },

      handleSelectCarClass(carClass) {
        this.row.jalan_plan_id.$model = carClass?.jalan_plan_id
        this.row.rakuten_plan_id.$model = carClass?.rakuten_plan_id

        forEach(this.row.target_periods.$each.$iter, item => {
          item._jalan_plan_id.$model = carClass?.jalan_plan_id
          item._rakuten_plan_id.$model = carClass?.rakuten_plan_id
        })

        this.updateRow()
      },

      handleAddPeriod() {
        this.row.target_periods.$model.push({ ...EMPTY_TARGET_PERIOD_OBJECT(this.jalanPlanId, this.rakutenPlanId) })
        this.updateRow()
      },

      handleRemovePeriod(index) {
        this.row.target_periods.$model.splice(index, 1)
        this.updateRow()
      },

      showAdd(index) {
        return parseInt(index) + 1 === this.row.target_periods.$model.length
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"
  @import "@/assets/styles/mixins/common.sass"

  .target-period-rows
    grid-column-start: 2
    grid-column-end: 8
    border-bottom: $element-border
    min-height: 53px

    .add-period
      display: flex
      height: 100%
      align-items: center
      max-width: 443px
      justify-content: flex-end

      &-button
        +icon-button($default-purple)
        margin-left: 5px
        user-select: none


  .setting-row
    display: contents

    .immutable
      color: $default-black
      font-size: 0.8rem
      padding: 0 10px
      text-overflow: ellipsis
      overflow: hidden
      white-space: nowrap

    .column
      &.actions
        padding: 5px 10px

        .delete
          border: 0
          border-radius: 50%
          width: 35px
          height: 35px
          fill: $default-purple
          padding: 0 !important

          &:hover
            fill: darken($default-purple, 10%)

          ::v-deep
            svg
              margin-right: 0px

      ::v-deep
        input
          padding: 10px
          border: $element-border
          border-radius: 0.25rem
          height: 2rem
          font-size: 0.8rem

          &:invalid
            border-color: $default-red
            color: $default-red

          &.large
            min-width: 300px

          &.short
            width: 100px
</style>
